import { createRouter, createWebHistory } from "vue-router";
import { message } from 'ant-design-vue';
import jwt_decoder from 'jwt-decode';
const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../components/account/Login.vue'),
    meta: {
        requireAut: false,
    }
  },  
  {
    path: '/',
    // name: 'Home',
    component: () => import('../components/home/Home.vue'),
    meta: {
      // requireAuth: true,
      title: '首页',
      icon: 'home',
    },
    children: [
      {
        path: '',
        name: 'Index',
        component: ()=>import('../components/content/HomeContent.vue'),
        meta: { 
          title: '首页',
          icon: 'home',
          requireAuth: false,
          }
      },
      {
        path: '/404-notfound',
        name: 'NotFound',
        component: ()=>import('../components/common/Error404.vue'),
        meta: { 
          title: '页面丢失',
          requireAuth: false,
         }
      },
      {
        path: '/blog',
        name: 'BlogPage',
        component: () => import('../components/blog/Blog.vue'),
        meta: {
          title: '博客详情页',
          icon: 'edit',
          requireAuth: false,
        }
      },
      {
        path: '/writeBlog',
        name: 'WriteBlog',
        component: () => import('../components/blog/WriteBlog.vue'),
        meta: {
          title: '博客详情页',
          icon: 'edit',
          requireAuth: true,
        }
      },
      {
        path: '/editBlog',
        name: 'EditBlog',
        component: () => import('../components/blog/EditBlog.vue'),
        meta: {
          title: '编辑博客',
          icon: 'edit',
          requireAuth: true,
        }
      },
      {
        path: '/space/:pid',
        // name: 'PersonSpace',
        component: () => import('../components/personspace/Index.vue'),
        meta: {
          title: '个人主页',
          icon: 'user',
          requireAuth: false,
        },
        children: [
          {
            path: '',
            name: 'MyBlogList',
            component: ()=>import('../components/personspace/MyBlogList.vue'),
            meta: {
              title: '我的博客',
              icon: 'user',
              requireAuth: false,
            },
          },
          {
            //子路由不要加/
            path: 'mycmts',
            name: 'MyCmtList',
            component: () => import('../components/personspace/MyCmtList.vue'),
            meta: {
              title: '我的评论',
              icon: 'user',
              requireAuth: false,
            },
          },
          {
            path: 'mystars',
            name: 'MyStarsBlog',
            component: () => import('../components/personspace/MyStarsBlog.vue'),
            meta: {
              title: '我的星选集',
              icon: 'user',
              requireAuth: true,
            },
          }
        ]
      },
      //end
      {
        path: '/msg/:userId',
        component: () => import('../components/msgs/Index.vue'),
        children: [
          {
            path: '',
            component: () => import('../components/msgs/SysMsg.vue'),
            meta: {
              title: '系统消息',
              icon: 'bell',
              requireAuth: true,
            }
          },
          {
            path: 'likes',
            component: () => import('../components/msgs/LikesMsg.vue'),
            meta: {
              title: '点赞消息',
              icon: 'bell',
              requireAuth: true,
            }
          },
          {
            path: 'cmts',
            component: () => import('../components/msgs/CmtsMsgs.vue'),
            meta: {
              title: '评论消息',
              icon: 'bell',
              requireAuth: true,
            }
          }
        ]
      },
      //end
      {
        path: '/setting/:userId',
        component: () => import('../components/personsettings/Index.vue'),
        meta: {
          title: '配置',
          icon: 'user',
          requireAuth: true,
        },
        children: [
          {
            path: '',
            component: () => import('../components/personsettings/BaseSetting.vue'),
            meta: {
              title: '基础配置',
              icon: 'user',
              requireAuth: true,
            },
          },
          {
            path: 'resetpswd',
            component: () => import('../components/personsettings/ResetPassword.vue'),
            meta: {
              title: '修改密码',
              icon: 'lock',
              requireAuth: true,
            },
          },
          {
            path: 'ask',
            component: () => import('../components/personsettings/AskQuestion.vue'),
            meta: {
              title: '问题咨询',
              icon: 'message',
              requireAuth: true,
            },
          }
        ]
      },
    ]
  }
]

const router = createRouter({
  base: process.env.NODE_ENV === 'production' ? '/' : '/',
  history: createWebHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth && to.fullPath !== '/login') {  // 判断该路由是否需要登录权限
    // console.log(to.fullPath)
    // console.log(to.meta.requireAuth)
    let enc_token = localStorage.getItem("userToken");
    if (enc_token!=null) {  // 通过vuex state获取当前的token是否存在
      let dec_token = jwt_decoder(enc_token);
      // console.log(dec_token)      
      let now_time = new Date();
      // console.log(now_time.getTime())
      // console.log(now_time.getTime()/1000 > dec_token["exp"])
      if (now_time.getTime()/1000 > dec_token["exp"]) {
        message.warning('token 失效, 请重新登陆!')
        localStorage.removeItem("userToken");
        next({
            path: '/login',
            name: 'login',
            query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
        })
      }else {
        next();
      }
    }
    else {
      message.warning('请登陆后,再撰写博客!')
      next({
          path: '/login',
          name: 'login',
          query: {redirect: to.fullPath}  // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
    }
  }
  else {
      next();
  }
})

export default router