export const URLS = {
  ACCOUNT: {
    REGISTER: {
      url: '/user/register',
      requireAuth: false,
    },
    LOGIN: {
      url: '/login/userLogin',
      requireAuth: false,
    },
    USER_INFO_UId: {
      url: '/user/userInfoUId',
      requireAuth: true,
    },
    GET_MAIL_CODE: {
      url: '/user/rstPswdSendCode',
      requireAuth: false
    },
    RST_PSWD: {
      url: '/user/rstUserPswd',
      requireAuth: false
    },
    UPT_USER_INFO: {
      url: '/user/uptUsrInfo',
      requireAuth: true,
    },
    SRH_USER_CB_DATA: {
      url: '/user/srhUsrCBData',
      requireAuth: false,
    },
    SRH_USR_MSGS: {
      url: '/user/msgs',
      requireAuth: true,
    },
    DEL_MSG: {
      url: '/user/delMsg',
      requireAuth: true
    },
    DEL_MY_CMT: {
      url: '/user/delMyCmt',
      requireAuth: true
    },
    DEL_MY_MSG: {
      url: '/user/delMyMsg',
      requireAuth: true,
    },
    USR_UNREAD_MSG_NUM: {
      url: '/user/usrUnReadMsgNum',
      requireAuth: true,
    },
    MSG_SIGN_READ: {
      url: '/user/msgSignRead',
      requireAuth: true,
    }
  },
  TAGS: {
    GET_ALL_TAGS: {
      url: '/tags/all',
      requireAuth: false,
    },
    HOT_TAGS: {
      url: '/tags/hotTags',
      requireAuth: false,
    }
  },
  BLOGS: {
    INSERT_BLOG: {
      url: '/blog/new',
      requireAuth: true,
    },
    BLOG_LIST: {
      url: '/blog/list',
      requireAuth: false,
    },
    BLOG_BY_ID: {
      url: '/blog/srhById',
      requireAuth: false,
    },
    RECORD_VISITOR_INFO: {
      url: '/blog/viewAdd',
      requireAuth: false,
    },
    RELATED_BLOGS: {
      url: '/blog/relatedBlog',
      requireAuth: false
    },
    CLICK_LIKES: {
      url: '/blog/clickLikes',
      requireAuth: true,
    },
    IF_CLICKED_LIKES: {
      url: '/blog/ifClickedLikes',
      requireAuth: true,
    },
    BLOG_LIKES_NUM: {
      url: '/blog/srhLikesByBId',
      requireAuth: false
    },
    BLOG_BY_UID: {
      url: '/blog/srchBlogByUId',
      requireAuth: false,
    },
    EDIT_MY_BLOG: {
      url: '/blog/editBlog',
      requireAuth: true,
    },
    BLOG_STARS_NUM: {
      url: '/blog/srhStarsByBId',
      requireAuth: false,
    },
    CLICK_STARS: {
      url: '/blog/clickStars',
      requireAuth: true
    },
    IF_CLICKED_STARS: {
      url: '/blog/ifClickedStars',
      requireAuth: true
    },
    SRH_STARS_BLOG: {
      url: '/blog/srhUsrStarsBlog',
      requireAuth: true,
    },
    DEL_BY_BID: {
      url: '/blog/delBlogByBid',
      requireAuth: true
    },
    SITE_BASE_DATA: {
      url: '/blog/siteData',
      requireAuth: false
    }
  },
  CMT: {
    BLOG_CMT_LIST: {
      url: '/cmt/listByBId',
      requireAuth: false,
    },
    WRITE_CMT: {
      url: '/cmt/wrCmt',
      requireAuth: true,
    },
    CMT_LIKES_LIST: {
      url: '/cmt/usrlikesList',
      requireAuth: true,
    },
    SRH_CMT_BY_UID: {
      url: '/cmt/usrCmtByUId',
      requireAuth: false,
    }
  }
}
export function needLogin(url){
  if(url === null) {
    return false
  }
  for(let key in URLS){
    for (let seckey in URLS[key]){
      if ( URLS[key][seckey].url === url) {
        return URLS[key][seckey].requireAuth;
      }
    }
  }
  return false;
}
export default { URLS, needLogin };