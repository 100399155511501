import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import { URLS, needLogin } from './api/allurl'
import CKEditor from '@ckeditor/ckeditor5-vue';
import store from './store';

axios.defaults.baseURL = process.env.NODE_ENV==='production' ? 'https://www.godljj.com/myblog' : 'http://localhost:9500'

axios.interceptors.request.use(
  config => {
    // console.log('config', config)
    if (localStorage.getItem('userToken') && needLogin(config.url)) {
      config!.headers!.Authorization = localStorage.getItem('userToken')|| '';
    }
    // console.log(needLogin(config.url));
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

createApp(App).use(router).use(Antd).use(CKEditor).use(store).mount('#app')


